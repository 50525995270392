'use strict';

angular.module('mvpcarrefourApp')
    .controller('MyVotesController', ['$scope', '$rootScope', 'Vote','Auth','$state',
        function($scope, $rootScope, Vote, Auth, $state) {

            // Si no es un usuario logado, redirigimos al login
            if (!Auth.isLoggedIn()) {
                return $state.go('main');
            };

            if ($rootScope.currentUser._id != null) {

                Vote.myVotes($rootScope.currentUser._id, "full").then(function(result) {
                    $scope.misvotos = result;
                })
            }
        }
    ]);
